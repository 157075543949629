import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Multiselect from 'vue-multiselect'
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueApexCharts)
Vue.component('MultiSelect', Multiselect)
Vue.component('ApexChart', VueApexCharts)

// import 'sweetalert2/src/sweetalert2.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/custom.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
