
<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" id="basicClassNav">
      <div class="colorblend">

      </div>
      <div class="container-fluid ">
        <span class="colorblend" alt=""></span>
        <div class="magnessNav">

          <b-navbar-brand href="#">
            <img src="../src/assets/BizLogo.png" class="NavLogoMain" alt="">
          </b-navbar-brand>
          <div>
            
            <button v-if="goToAdminCheck" class="linkSimple" @click="adminRotate()"> Go to Admin Panel</button>
            <button v-if="showSidebar" class="linkSimple" @click="userRotate()"> Go to Dashboard</button>
            <button  v-if="showLogout" class="basicAddButton" @click="logoutFunc()"><b-icon icon="box-arrow-right"></b-icon> Logout</button>
          </div>
        </div>
      </div>
    </b-navbar>
    <div class="container-fluid">
      
      <SideBar v-if="showSidebar"/>

      <router-view />
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import SideBar from './views/sidebarMenu.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld
    // BNavbarBrand,
    // BNavbar,
    SideBar
  },
  data() {
    return {
      token:localStorage.getItem('token'),
      goToAdmin: ['SalesOverview', 'SupplierOverview', 'CustomerOverview', 'ManagementOverview'], // Array of page names where you want to show the sidebar
      pagesWithLogout: ['BranchCRM','RoleCRM','DepartmentCRM','TeamCRM','EmployeeCRM','PermissionsCRM','ActivityLogsCRUD','SalesOverview', 'SupplierOverview', 'CustomerOverview', 'ManagementOverview','Branch','Role','Permissions', 'Assignment','Employee'], // Array of page names where you want to show the sidebar
      pagesWithSidebar: ['BranchCRM','RoleCRM','DepartmentCRM','TeamCRM','EmployeeCRM','PermissionsCRM','ActivityLogsCRUD','Branch','Role','Permissions', 'Assignment','Employee'] // Array of page names where you want to show the sidebar
    };
  },
  methods: {
    adminRotate(){
      this.$router.push({ name: 'Branch' });
    },
    userRotate(){
      this.$router.push({ name: 'ManagementOverview' });
    },
    logoutFunc(){
      axios.post( '/api/logoutapi', 
            { headers: { 'Access-Control-Allow-Origin': '*' } })

        .then(response => {
            console.log(response.data)
            this.$router.push({ name: 'Login' });
                    
            localStorage.clear(); 
                
        })
        .catch(error => {
            console.log(error);
        });
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    showSidebar() {
      return this.pagesWithSidebar.includes(this.currentRoute);
    },
    showLogout(){
      return this.pagesWithLogout.includes(this.currentRoute);

    },
    goToAdminCheck(){
      return this.goToAdmin.includes(this.currentRoute) && this.token =='Super Admin';

    },
    goToUserCheck(){
      return this.pagesWithSidebar.includes(this.currentRoute) && this.token == 'Sales Manager Admin'|| this.token == 'Sales Manager';
      
    }
  } 
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.basicAddButton {
    background: #3498db;
    font-size: 12px;
    padding: 4px 10px !important;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    border: none;
}
.multiselect__option, [data-selected] {
    padding: 6px !important;
    min-height: 20px !important;
    line-height: 8px !important;
    font-size: 10px !important;
    font-weight: 600 !important;
}
.multiselect__tag {
    font-size: 10px !important;
}
.multiselect__tag-icon {
    line-height: 18px !important;
}
.multiselect__placeholder {
    margin-bottom: 2px !important;
    padding-top: 2px;
}
.multiselect__tags {
    min-height: 30px !important; 
    max-height: 30px !important; 
    padding: 4px 40px 0 4px !important; 
    font-size: 12px !important; 
    overflow: hidden !important
}
.multiselect__select {
    width: 30px !important;
    height: 30px !important;
}
.multiselect__input{
  font-size: 12px !important;
}
.filterLabel{
  text-align: left !important;
  width: 100% !important;
  margin-left: 0px !important;
  font-family: 'Montserrat' !important;
  font-size: 11px !important;
  font-weight: 600 !important;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

*::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122,153,217)), color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148)));
}

.linkSimple{
  background: transparent;
  border: none;
  font-size: 11px;
  margin-right: 10px;
  text-decoration: underline;
  color: #4b5ca5;
  font-weight: 600;
}
#basicClassNav{
  background-color: white;
  position: relative;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  z-index: 999;
}
.colorblend{
    background-image: -webkit-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: -moz-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: -ms-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: linear-gradient(to right, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    width: 110%;
    position: absolute;
    top: -3px;
    left: -3%;
    background: #f7f9fa;
    height: 6px;
    background-image: linear-gradient(90deg, #34495e, #34495e 25%, #9b59b6 0, #9b59b6 35%, #3498db 0, #3498db 45%, #62cb31 0, #62cb31 55%, #ffb606 0, #ffb606 65%, #e67e22 0, #e67e22 75%, #e74c3c 85%, #e74c3c 0, #c0392b 0, #c0392b);
    background-size: 100% 6px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
}
.magnessNav{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.NavLogoMain{
  width: auto;
  height: 100%;
}
</style>
