import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '*',
      redirect: to => {
        const isAuthenticated = checkAuthentication();
        const isAdmin = checkAdminRole();
        return isAuthenticated ? (isAdmin ? '/branch' : '/management') : '/login';
      }
    },
    {
      path: '/',
      redirect: '/login'
      // name: 'landingPage',
      // component: () => import('../views/salesOverviewThree.vue'),
      // meta: { requiresAuth: true, isAdmin: false }
    },
    {
      path: '/sales',
      name: 'SalesOverview',
      component: () => import('../views/salesOverviewThree.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/supplier',
      name: 'SupplierOverview',
      component: () => import('../views/supplierOverviewThree.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/customer',
      name: 'CustomerOverview',
      component: () => import('../views/customer/customerMaster.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/customerold',
      name: 'CustomerOverviewOld',
      component: () => import('../views/performanceOverviewThree.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/management',
      name: 'ManagementOverview',
      component: () => import('../views/managementOverviewThree.vue'),
      meta: { requiresAuth: true },
      props: true
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('../views/registerUser.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/loginUser.vue'),
    },
    {
      path: '/set-password/:hexCode',
      name: 'SetPassword',
      component: () => import('../views/setPassword.vue'),
    },
    {
      path: '/reset-password/:hexCode',
      name: 'ResetPassword',
      component: () => import('../views/resetPassword.vue'),
    },
    {
      path: '/forget-password',
      name: 'ForgetPassword',
      component: () => import('../views/forgetPassword.vue'),
    },
    {
      path: '/branchCRM',
      name: 'BranchCRM',
      component: () => import('../views/branchCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/roleCRM',
      name: 'RoleCRM',
      component: () => import('../views/roleCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/activitylogs',
      name: 'ActivityLogsCRUD',
      component: () => import('../views/ActivityLogsCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/employee',
      name: 'Employee',
      component: () => import('../views/employeeCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/departmentCRM',
      name: 'DepartmentCRM',
      component: () => import('../views/departmentCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/teamCRM',
      name: 'TeamCRM',
      component: () => import('../views/teamCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/employeeCRM',
      name: 'EmployeeCRM',
      component: () => import('../views/employeeCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/permissionsCRM',
      name: 'PermissionsCRM',
      component: () => import('../views/permissionsCRUDCRM.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },

    
    {
      path: '/branch',
      name: 'Branch',
      component: () => import('../views/branchCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/role',
      name: 'Role',
      component: () => import('../views/roleCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/permissions',
      name: 'Permissions',
      component: () => import('../views/permissionsCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    {
      path: '/assignment',
      name: 'Assignment',
      component: () => import('../views/assignmentCRUD.vue'),
      meta: { requiresAuth: true, isAdmin: true }
    },
    
  ],
})


router.beforeEach((to, from, next) => {
  const isAuthenticated = checkAuthentication();
  const isAdmin = checkAdminRole();

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.isAdmin && !isAdmin) {
    next('/management');
  } else if (to.name === 'login' && isAuthenticated) {
    next(isAdmin ? '/branch' : '/management');
  } else {
    next();
  }
});

function checkAuthentication() {
  const token = localStorage.getItem('token');
  return !!token;
}

function checkAdminRole() {
  const role = localStorage.getItem('token'); // Assuming role is stored in token
  return role === 'Super Admin'; // Check if the role stored in token is 'admin'
}

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   console.log(canNavigate(to))
//   if (!canNavigate(to)) {
//     console.log("to ", to)
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// // ? For splash screen
// // Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

export default router
